<template>
    <div>
        <b-form-select v-model="selected"
                       :options="options"
                       @input="handleInput"/>
        <span class="invalid-feedback-custom"
              v-show="validateError"
              v-html="validateError"/>
    </div>
</template>
<script>
    export default {
        props: {
            value: {
                default: null
            },
            validateError: {
                type: String,
                default: null
            },
            fromRange: {
                type: Number,
                default: 1
            },
            toRange: {
                type: Number,
                default: 24
            }
        },
        data() {
            return {
                selected: null,
                options: [{value: null, text: this.$t('select')}]
            }
        },
        watch: {
            value: function(newValue) {
                this.selected = newValue
            }
        },
        created() {
            this.getOptions();
        },
        methods: {
            getOptions() {
                this.range(this.fromRange, this.toRange).forEach((item) => {
                    this.options.push({value: item, text: this.$t('period_x', {period: item})});
                })
                this.selected = this.value
            },
            handleInput(event) {
                this.$emit('input', event)
            },
            range(start, end) {
                return Array(end - start + 1).fill().map((_, idx) => start + idx)
            }
        }

    }
</script>
